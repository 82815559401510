.empty-state {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background-color: var(--soft-gray);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100% - 60px);
}

.empty-state.full-height {
  height: 100%;
}

.empty-state img {
  width: 220px;
}

.empty-state .heading-large-light,
.empty-state .heading-large-contrast {
  margin: 40px 0;
  width: 240px;
  text-align: center;
}