.heading-large {
  color: var(--black);
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.heading-large-contrast {
  color: var(--white);
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.heading-large-light {
  color: var(--black);
  font-size: 19px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 16px;
}

.heading {
  color: var(--black);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.heading-contrast {
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.button {
  font-weight: 500;
  color: var(--black);
  font-size: 16px;
  line-height: 20px;
}

.button-accent {
  font-weight: 500;
  color: var(--green);
  font-size: 16px;
  line-height: 20px;
}

.button-contrast {
  font-weight: 500;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}

.body-error {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #EE1C10;
  margin-bottom: 16px;
}

.body-light {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--light-gray);
}

.body {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--black);
}

.body-contrast {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--white);
}

.body.margin,
.body-light.margin {
  margin-bottom: 8px;
}