@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap');

:root {
  --green: #39B54A;
  --soft-green: #D3EBD1;
  --softer-green: #EAf6EA;
  --light-green: #92CC8D;
  --dark-green: #1D6839;
  --white: #FFFFFF;
  --black: #11171C;
  --light-gray: #969696;
  --lighter-gray: #CCCCCC;
  --section-header: #F5F5F5;
  --soft-gray: #F8F8F8;
  --soft-green: #D3EBD1;
  --overlay: rgba(21, 21, 21, 0.85);
  --cubic-bezier: cubic-bezier(.16,.62,.83,.67);
  --paper: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  --paper-2: rgba(21, 21, 21, 0.16) 0px 3px 10px, rgba(21, 21, 21, 0.23) 0px 3px 10px;
}

html {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: var(--white);
  color: var(--black);
}

html,
body,
#root {
  height: 100%;
}

.icon {
  width: 24px;
  height: 24px;
}

.input,
.date-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--lighter-gray);
  outline: none;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
}

textarea,
input {
  font: inherit;
}

textarea.input {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.Dropdown-root {
  margin-bottom: 16px;
}

.Dropdown-control {
  border: 1px solid var(--lighter-gray);
  border-radius: 4px;
  padding: 10px 52px 10px 10px;
}

.Dropdown-menu {
  border: 1px solid var(--lighter-gray);
  border-radius: 4px;
}

.Dropdown-option {
  color: var(--light-gray);
}

.Dropdown-option:hover,
.Dropdown-option.is-selected {
  background-color: var(--softer-green);
  color: var(--black);
}

.Dropdown-arrow {
  top: 16px;
}

.react-datepicker-wrapper {
  width: 100%;
}

strong {
  font-weight: bold;
}

a {
  transition: opacity .3s var(--cubic-bezier);
}

a.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input {
  cursor: pointer;
}

.checkbox .heading {
  margin-bottom: 0;
  margin-right: 8px;
}