.tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tabs nav {
  display: flex;
  align-items: center;
}

.tabs nav button {
  padding: 10px 10px 8px;
  margin: 0;
  background: 0;
  outline: 0;
  border: 0;
  flex: 1;
  border-bottom: 2px solid transparent;
  transition: border-color .3s var(--cubic-bezier);
}

.tabs nav button.active {
  border-color: var(--green);
}