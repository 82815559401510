.drawer .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--overlay);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s var(--cubic-bezier) 0s;
}

.drawer.open .overlay {
  pointer-events: auto;
  opacity: 1;
}

.drawer nav {
  width: 256px;
  height: 100%;
  background-color: var(--white);
  transform: translateX(-256px);
  transition: transform 0.3s ease-in-out 0s;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer.open nav {
  transform: none;
}

.drawer-header {
  width: 100%;
  height: 60px;
  background-color: var(--green);
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.drawer-header .heading-large-contrast {
  margin-bottom: 0;
}

.drawer-header .avatar {
  margin-right: 16px;
}

.drawer nav a {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: block;
  padding: 4px;
  text-decoration: none;
}

.drawer nav a p,
.logout p {
  margin-bottom: 0;
}

.drawer nav a.active section {
  background-color: var(--soft-green);
}

.drawer nav a.active p {
  color: var(--green);
}

.drawer nav a section {
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.drawer nav a.active svg {
  fill: var(--green);
}

.drawer svg {
  margin-right: 8px;
}

.drawer .version {
  text-align: center;
  margin-bottom: 8px;
}

.drawer .logout {
  border: none;
  outline: none;
  padding: 8px;
  display: block;
  background: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.drawer-button {
  position: relative;
  width: 60px;
  height: 60px;
}

.badge {
  background-color: var(--green);
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  color: var(--white);
  font-size: 12px;
  height: 20px;
  line-height: 14px;
  padding: 3px;
  text-align: center;
  min-width: 20px;
}

.drawer-button .badge {
  position: absolute;
  right: 10px;
  top: 10px;
}

.drawer nav a.feedback section {
  justify-content: space-between;
}

.drawer nav a.feedback section div:first-child {
  display: flex;
  align-items: center;
}
