.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-sizing: border-box;
  z-index: 1;
  border-bottom: 1px solid var(--lighter-gray);
  position: sticky;
  top: 0;
  background-color: var(--white);
  flex-shrink: 0;
}

.app-header img {
  width: 60px;
}

.app-header h1 {
  margin-bottom: 0;
}

.header-button {
  min-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.header-button:first-child {
  left: 0;
}

.header-button:last-child {
  right: 0;
}

button.header-button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

.app-header .button.text {
  margin-right: 16px;
}