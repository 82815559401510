.numeric-input {
  margin-bottom: 16px;
}

.numeric-input section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
}

.numeric-input section .heading {
  margin: 0 12px;
  font-family: 'Roboto Mono', monospace;
  min-width: 44px;
  text-align: center;
}

.numeric-input button {
  padding: 0;
  border: none;
  background-color: none;
  outline: none;
  box-shadow: var(--paper);
  transition: box-shadow .3s var(--cubic-bezier);
  border-radius: 4px;
}

.numeric-input button:active {
  box-shadow: var(--paper-2);
}

.numeric-input button img {
  display: block;
}