.log-form .heading-large:first-of-type::first-letter,
.plan-form .heading-large:first-of-type::first-letter,
.log .heading-large:first-of-type::first-letter {
  text-transform: capitalize;
}

.log-form .row,
.plan-form .row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.log-form .row > div,
.plan-form .row > div {
  min-width: 213px;
  margin-right: 32px;
}
