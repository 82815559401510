.section-header {
  padding: 4px 8px;
  background-color: var(--section-header);
}

.list-item {
  text-decoration: none;
  display: block;
}

button.list-item {
  border: none;
  outline: none;
  background: none;
  text-align: left;
  padding: 0;
  appearance: none;
  width: 100%;
}

.list-item > section {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.list-item .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.list-item .text-container.has-avatar {
  width: calc(100% - 48px);
}

.list-item .text-container h1,
.list-item .text-container p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-item .heading,
.list-item .heading-contrast {
  margin-bottom: 0;
}

.list-item .heading::first-letter,
.list-item .heading-contrast::first-letter {
  text-transform: capitalize;
}

.list-item .avatar {
  margin-right: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.avatar.contrast {
  background-color: var(--white);
}

.divider {
  box-sizing: border-box;
  height: 1px;
  background-color: var(--lighter-gray);
  border: 0;
  margin: 0;
}

.divider.hidden {
  background-color: transparent;
}

.divider.margin {
  margin-bottom: 16px;
}

.list-item.active {
  background-color: var(--green);
}

.list-item.active .heading,
.list-item.active .body,
.list-item.active .body-light {
  color: var(--white);
}

.list-item.active .avatar {
  background-color: var(--white);
}

.list-item.active .avatar .button-contrast {
  color: var(--green);
}

.list-item.active .divider {
  background-color: transparent;
}

.list-item .dot {
  width: 10px;
  height: 10px;
  margin: 1px 4px;
  background-color: var(--green);
  display: inline-block;
  border-radius: 5px;
}