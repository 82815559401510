button {
  cursor: pointer;
  font: inherit;
}

button.button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.button.filled {
  background: var(--green);
  box-shadow: var(--paper);
  border-radius: 4px;
  padding: 8px 12px;
  min-width: 200px;
  display: block;
  margin: 0 auto;
  transition: box-shadow .3s var(--cubic-bezier), opacity .3s var(--cubic-bezier);
}

.button.text {
  transition: text-shadow .3s var(--cubic-bezier), opacity .3s var(--cubic-bezier);
}

button.button.filled:active {
  box-shadow: var(--paper-2);
}

button.button.text:active {
  text-shadow: var(--paper-2);
}

button.button:disabled {
  opacity: 0.5;
}

@media (min-width: 768px) {
  form > .button.filled {
    margin-bottom: 16px;
  }
}