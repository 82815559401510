.login {
  height: 100%;
  background-image: url(../assets/background.jpg);
  background-position: center;
  box-sizing: border-box;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login img {
  width: 200px;
  margin: 0 auto;
  display: block;
}
