@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:root {
  --green: #39B54A;
  --soft-green: #D3EBD1;
  --softer-green: #EAf6EA;
  --light-green: #92CC8D;
  --dark-green: #1D6839;
  --white: #FFFFFF;
  --black: #11171C;
  --light-gray: #969696;
  --lighter-gray: #CCCCCC;
  --section-header: #F5F5F5;
  --soft-gray: #F8F8F8;
  --soft-green: #D3EBD1;
  --overlay: rgba(21, 21, 21, 0.85);
  --cubic-bezier: cubic-bezier(.16,.62,.83,.67);
  --paper: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  --paper-2: rgba(21, 21, 21, 0.16) 0px 3px 10px, rgba(21, 21, 21, 0.23) 0px 3px 10px;
}

html {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
  background-color: var(--white);
  color: #11171C;
  color: var(--black);
}

html,
body,
#root {
  height: 100%;
}

.icon {
  width: 24px;
  height: 24px;
}

.input,
.date-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #CCCCCC;
  border: 1px solid var(--lighter-gray);
  outline: none;
  font-size: 16px;
  line-height: 20px;
  color: #11171C;
  color: var(--black);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
}

textarea,
input {
  font: inherit;
}

textarea.input {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.Dropdown-root {
  margin-bottom: 16px;
}

.Dropdown-control {
  border: 1px solid #CCCCCC;
  border: 1px solid var(--lighter-gray);
  border-radius: 4px;
  padding: 10px 52px 10px 10px;
}

.Dropdown-menu {
  border: 1px solid #CCCCCC;
  border: 1px solid var(--lighter-gray);
  border-radius: 4px;
}

.Dropdown-option {
  color: #969696;
  color: var(--light-gray);
}

.Dropdown-option:hover,
.Dropdown-option.is-selected {
  background-color: #EAf6EA;
  background-color: var(--softer-green);
  color: #11171C;
  color: var(--black);
}

.Dropdown-arrow {
  top: 16px;
}

.react-datepicker-wrapper {
  width: 100%;
}

strong {
  font-weight: bold;
}

a {
  transition: opacity .3s cubic-bezier(.16,.62,.83,.67);
  transition: opacity .3s var(--cubic-bezier);
}

a.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input {
  cursor: pointer;
}

.checkbox .heading {
  margin-bottom: 0;
  margin-right: 8px;
}
.heading-large {
  color: var(--black);
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.heading-large-contrast {
  color: var(--white);
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 16px;
}

.heading-large-light {
  color: var(--black);
  font-size: 19px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 16px;
}

.heading {
  color: var(--black);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.heading-contrast {
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.button {
  font-weight: 500;
  color: var(--black);
  font-size: 16px;
  line-height: 20px;
}

.button-accent {
  font-weight: 500;
  color: var(--green);
  font-size: 16px;
  line-height: 20px;
}

.button-contrast {
  font-weight: 500;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}

.body-error {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #EE1C10;
  margin-bottom: 16px;
}

.body-light {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--light-gray);
}

.body {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--black);
}

.body-contrast {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--white);
}

.body.margin,
.body-light.margin {
  margin-bottom: 8px;
}
.page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page .content {
  padding: 16px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
}

.page .medium.row {
  display: flex;
  flex: 1 1;
  height: 100%;
}

.page .medium.row > section:last-child,
.page .medium.row > form:last-child {
  width: calc(100% - 300px);
  overflow: auto;
}

.list-container {
  width: 300px;
  box-sizing: border-box;
  border-right: 1px solid var(--lighter-gray);
  min-height: 100%;
}

@media (min-width: 768px) {
  .page {
    height: 100%;
  }

  .page .content {
    padding: 0;
  }

  .page .container {
    padding: 16px;
  }

  .page .content .heading-large {
    margin-bottom: 0;
  }

  .page:not(.about) .content header {
    padding: 16px;
    margin-bottom: 0;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lighter-gray);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--white);
  }

  .list {
    height: calc(100% - 60px);
    overflow: auto;
  }
}

.page.admin .page {
  flex: 1 1;
}

.page.admin .page .content {
  padding: 16px;
}

.page.admin .page .content .heading-large {
  margin-bottom: 16px;
}

.prev-next {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.prev-next a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logs .search {
  padding: 16px;
}

.logs .row {
  display: flex;
  align-items: center;
}

.logs input {
  box-sizing: border-box;
  margin-bottom: 0;
  width: 70px;
  margin-left: 16px;
}

.logs .Dropdown-root {
  width: 248px;
}

.logs .list {
  height: auto;
}

.profile .body {
  margin-bottom: 16px;
}

.list .Dropdown-root {
  margin-bottom: 0;
}

.list .Dropdown-control {
  padding: 11.5px 52px 11.5px 10px;
  border: 0;
  border-bottom: 1px solid var(--lighter-gray);
  border-radius: 0;
}

.list .Dropdown-arrow {
  top: 17.5px;
}
.empty-state {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background-color: var(--soft-gray);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100% - 60px);
}

.empty-state.full-height {
  height: 100%;
}

.empty-state img {
  width: 220px;
}

.empty-state .heading-large-light,
.empty-state .heading-large-contrast {
  margin: 40px 0;
  width: 240px;
  text-align: center;
}
.loading {
  height: 100%;
  background-image: url(/static/media/background.d6c1d125.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 40px 0;
}

.loading img {
  width: 200px;
  margin: 0 auto;
  display: block;
}

.ripple {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
button {
  cursor: pointer;
  font: inherit;
}

button.button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.button.filled {
  background: var(--green);
  box-shadow: var(--paper);
  border-radius: 4px;
  padding: 8px 12px;
  min-width: 200px;
  display: block;
  margin: 0 auto;
  transition: box-shadow .3s var(--cubic-bezier), opacity .3s var(--cubic-bezier);
}

.button.text {
  transition: text-shadow .3s var(--cubic-bezier), opacity .3s var(--cubic-bezier);
}

button.button.filled:active {
  box-shadow: var(--paper-2);
}

button.button.text:active {
  text-shadow: var(--paper-2);
}

button.button:disabled {
  opacity: 0.5;
}

@media (min-width: 768px) {
  form > .button.filled {
    margin-bottom: 16px;
  }
}
.login {
  height: 100%;
  background-image: url(/static/media/background.d6c1d125.jpg);
  background-position: center;
  box-sizing: border-box;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login img {
  width: 200px;
  margin: 0 auto;
  display: block;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-sizing: border-box;
  z-index: 1;
  border-bottom: 1px solid var(--lighter-gray);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--white);
  flex-shrink: 0;
}

.app-header img {
  width: 60px;
}

.app-header h1 {
  margin-bottom: 0;
}

.header-button {
  min-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.header-button:first-child {
  left: 0;
}

.header-button:last-child {
  right: 0;
}

button.header-button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

.app-header .button.text {
  margin-right: 16px;
}
.section-header {
  padding: 4px 8px;
  background-color: var(--section-header);
}

.list-item {
  text-decoration: none;
  display: block;
}

button.list-item {
  border: none;
  outline: none;
  background: none;
  text-align: left;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
}

.list-item > section {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.list-item .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.list-item .text-container.has-avatar {
  width: calc(100% - 48px);
}

.list-item .text-container h1,
.list-item .text-container p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-item .heading,
.list-item .heading-contrast {
  margin-bottom: 0;
}

.list-item .heading::first-letter,
.list-item .heading-contrast::first-letter {
  text-transform: capitalize;
}

.list-item .avatar {
  margin-right: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.avatar.contrast {
  background-color: var(--white);
}

.divider {
  box-sizing: border-box;
  height: 1px;
  background-color: var(--lighter-gray);
  border: 0;
  margin: 0;
}

.divider.hidden {
  background-color: transparent;
}

.divider.margin {
  margin-bottom: 16px;
}

.list-item.active {
  background-color: var(--green);
}

.list-item.active .heading,
.list-item.active .body,
.list-item.active .body-light {
  color: var(--white);
}

.list-item.active .avatar {
  background-color: var(--white);
}

.list-item.active .avatar .button-contrast {
  color: var(--green);
}

.list-item.active .divider {
  background-color: transparent;
}

.list-item .dot {
  width: 10px;
  height: 10px;
  margin: 1px 4px;
  background-color: var(--green);
  display: inline-block;
  border-radius: 5px;
}
.numeric-input {
  margin-bottom: 16px;
}

.numeric-input section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
}

.numeric-input section .heading {
  margin: 0 12px;
  font-family: 'Roboto Mono', monospace;
  min-width: 44px;
  text-align: center;
}

.numeric-input button {
  padding: 0;
  border: none;
  background-color: none;
  outline: none;
  box-shadow: var(--paper);
  transition: box-shadow .3s var(--cubic-bezier);
  border-radius: 4px;
}

.numeric-input button:active {
  box-shadow: var(--paper-2);
}

.numeric-input button img {
  display: block;
}
.log-form .heading-large:first-of-type::first-letter,
.plan-form .heading-large:first-of-type::first-letter,
.log .heading-large:first-of-type::first-letter {
  text-transform: capitalize;
}

.log-form .row,
.plan-form .row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.log-form .row > div,
.plan-form .row > div {
  min-width: 213px;
  margin-right: 32px;
}

.feedback-item > section {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.feedback-item .divider {
  width: 100%;
  margin: 0;
}

.feedback-item .heading {
  margin-bottom: 0;
}

.feedback-item header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedback-item header a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.feedback-item .heading::first-letter {
  text-transform: capitalize;
}

.about .content,
.profile {
  padding: 16px;
}

.about .content header,
.profile header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.about .avatar.large,
.profile .avatar.large {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 16px;
}

.about header section,
.profile header section {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.about header h1,
.profile header h1 {
  margin-bottom: 0;
}
.drawer .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--overlay);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s var(--cubic-bezier) 0s;
}

.drawer.open .overlay {
  pointer-events: auto;
  opacity: 1;
}

.drawer nav {
  width: 256px;
  height: 100%;
  background-color: var(--white);
  transform: translateX(-256px);
  transition: transform 0.3s ease-in-out 0s;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer.open nav {
  transform: none;
}

.drawer-header {
  width: 100%;
  height: 60px;
  background-color: var(--green);
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.drawer-header .heading-large-contrast {
  margin-bottom: 0;
}

.drawer-header .avatar {
  margin-right: 16px;
}

.drawer nav a {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: block;
  padding: 4px;
  text-decoration: none;
}

.drawer nav a p,
.logout p {
  margin-bottom: 0;
}

.drawer nav a.active section {
  background-color: var(--soft-green);
}

.drawer nav a.active p {
  color: var(--green);
}

.drawer nav a section {
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.drawer nav a.active svg {
  fill: var(--green);
}

.drawer svg {
  margin-right: 8px;
}

.drawer .version {
  text-align: center;
  margin-bottom: 8px;
}

.drawer .logout {
  border: none;
  outline: none;
  padding: 8px;
  display: block;
  background: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.drawer-button {
  position: relative;
  width: 60px;
  height: 60px;
}

.badge {
  background-color: var(--green);
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  color: var(--white);
  font-size: 12px;
  height: 20px;
  line-height: 14px;
  padding: 3px;
  text-align: center;
  min-width: 20px;
}

.drawer-button .badge {
  position: absolute;
  right: 10px;
  top: 10px;
}

.drawer nav a.feedback section {
  justify-content: space-between;
}

.drawer nav a.feedback section div:first-child {
  display: flex;
  align-items: center;
}

.tabs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.tabs nav {
  display: flex;
  align-items: center;
}

.tabs nav button {
  padding: 10px 10px 8px;
  margin: 0;
  background: 0;
  outline: 0;
  border: 0;
  flex: 1 1;
  border-bottom: 2px solid transparent;
  transition: border-color .3s var(--cubic-bezier);
}

.tabs nav button.active {
  border-color: var(--green);
}
