.feedback-item > section {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.feedback-item .divider {
  width: 100%;
  margin: 0;
}

.feedback-item .heading {
  margin-bottom: 0;
}

.feedback-item header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedback-item header a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.feedback-item .heading::first-letter {
  text-transform: capitalize;
}
