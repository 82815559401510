.page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page .content {
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.page .medium.row {
  display: flex;
  flex: 1;
  height: 100%;
}

.page .medium.row > section:last-child,
.page .medium.row > form:last-child {
  width: calc(100% - 300px);
  overflow: auto;
}

.list-container {
  width: 300px;
  box-sizing: border-box;
  border-right: 1px solid var(--lighter-gray);
  min-height: 100%;
}

@media (min-width: 768px) {
  .page {
    height: 100%;
  }

  .page .content {
    padding: 0;
  }

  .page .container {
    padding: 16px;
  }

  .page .content .heading-large {
    margin-bottom: 0;
  }

  .page:not(.about) .content header {
    padding: 16px;
    margin-bottom: 0;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lighter-gray);
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--white);
  }

  .list {
    height: calc(100% - 60px);
    overflow: auto;
  }
}

.page.admin .page {
  flex: 1;
}

.page.admin .page .content {
  padding: 16px;
}

.page.admin .page .content .heading-large {
  margin-bottom: 16px;
}

.prev-next {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.prev-next a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logs .search {
  padding: 16px;
}

.logs .row {
  display: flex;
  align-items: center;
}

.logs input {
  box-sizing: border-box;
  margin-bottom: 0;
  width: 70px;
  margin-left: 16px;
}

.logs .Dropdown-root {
  width: 248px;
}

.logs .list {
  height: auto;
}

.profile .body {
  margin-bottom: 16px;
}

.list .Dropdown-root {
  margin-bottom: 0;
}

.list .Dropdown-control {
  padding: 11.5px 52px 11.5px 10px;
  border: 0;
  border-bottom: 1px solid var(--lighter-gray);
  border-radius: 0;
}

.list .Dropdown-arrow {
  top: 17.5px;
}