.about .content,
.profile {
  padding: 16px;
}

.about .content header,
.profile header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.about .avatar.large,
.profile .avatar.large {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 16px;
}

.about header section,
.profile header section {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.about header h1,
.profile header h1 {
  margin-bottom: 0;
}